import { FeatureType } from 'commons';

export default class Features {
    TasksEnabled = false;
    TasksContactEnabled = false;
    ContactsGoogleSynchroEnabled = false;
    AutomaticallyUpdateAppEnabled = false;
    Experimental = false;
    MarketingCampaignEditor = false;
    DocumentTemplateList = false;
    ClientConfigErrors = false;
    GeneralImport = false;
    Notifications = false;
    OnlineMeeting = false;
    ElectronicSignature = false;
    NewAdmin = false;
    NewTasks = false;
    NewContacts = false;
    UShop = false;
    ProspectionTool = false;
    NewEmails = false;
    NewTemplates = false;
    MRS = false;
    NewMassMailing = false;
    Analytics = false;
    ChatGPT = false;
    BuyerFlow = false;
    RentalFlow = false;

    constructor(preferences, officeId) {
        this.setFeatures(preferences, officeId);
    }

    setFeatures = (preferences, officeId) => {
        if (!preferences?.features || !officeId) {
            return;
        }
        let features = JSON.parse(preferences.features);
        for (let feature of features) {
            let id = parseInt(feature.id);
            let officeIds = feature.officeIds.split(',');
            let enabled = feature.officeIds === '*' ? true : officeIds.includes(officeId.toString());
            switch (id) {
                case FeatureType.Tasks:
                    this.TasksEnabled = enabled;
                    this.TasksContactEnabled = feature.params === '*';
                    break;

                case FeatureType.ContactsGoogleSynchro:
                    this.ContactsGoogleSynchroEnabled = enabled;
                    break;

                case FeatureType.UpdateApp:
                    this.AutomaticallyUpdateAppEnabled = enabled;
                    break;

                case FeatureType.MarketingCampaignEditor:
                    this.MarketingCampaignEditor = enabled;
                    break;

                case FeatureType.DocumentTemplateList:
                    this.DocumentTemplateList = enabled;
                    break;

                case FeatureType.ClientConfigErrors:
                    this.ClientConfigErrors = enabled;
                    break;

                case FeatureType.GeneralImport:
                    this.GeneralImport = enabled;
                    break;

                case FeatureType.Notifications:
                    this.Notifications = enabled;
                    break;

                case FeatureType.OnlineMeeting:
                    this.OnlineMeeting = enabled;
                    break;

                case FeatureType.ElectronicSignature:
                    this.ElectronicSignature = enabled;
                    break;

                case FeatureType.NewAdmin:
                    this.NewAdmin = enabled;
                    break;

                case FeatureType.NewTasks:
                    this.NewTasks = enabled;
                    break;

                case FeatureType.NewContacts:
                    this.NewContacts = enabled;
                    break;

                case FeatureType.UShop:
                    this.UShop = enabled;
                    break;

                case FeatureType.ProspectionTool:
                    this.ProspectionTool = enabled;
                    break;

                case FeatureType.NewEmails:
                    this.NewEmails = enabled;
                    break;

                case FeatureType.NewTemplates:
                    this.NewTemplates = enabled;
                    break;

                case FeatureType.NewMassMailing:
                    this.NewMassMailing = enabled;
                    break;

                case FeatureType.MRS:
                    this.MRS = enabled;
                    break;

                case FeatureType.Analytics:
                    this.Analytics = enabled;
                    break;

                case FeatureType.ChatGPT:
                    this.ChatGPT = enabled;
                    break;

                case FeatureType.BuyerFlow:
                    this.BuyerFlow = enabled;
                    break;

                case FeatureType.RentalFlow:
                    this.RentalFlow = enabled;

                default:
                    break;
            }
        }
    };
}
